$font-stack: Helvetica, sans-serif;
@import './src/Login/Resources/scss/color.scss';

.login{
	font: 100% $font-stack;
	color: $color-white;

	.form-floating{
		label{
			color:#7d7b7b;
			padding: 0.7rem .75rem;
		}
		.form-control{
			padding:0.9rem 0.75rem 0rem 0.75rem;
			height:calc(2.5rem + 2px);
		}
	}

	form#language-switch{
		position: absolute;
		top: 20px;
		right: 20px;
		display: flex;
		gap: 10px;
	}
	
	&.height-vh{
		height:100vh;
	}
	
	a{
		color:$color-white;
		text-decoration:none;
		&:hover{
			color:$color-white;
			opacity:0.8;
		}
	}

	.bg-custom{
		background-color: $primary-background-color;
		.btn-custom{
			background-color: $primary-button-color;
			color:$color-white;
			width:100%;
		}
	}

	.alerts,form[name='form']{
		margin:0px auto; 
	}
	
	form{
		.form-check-input{
			top: -4px;
			position: relative;
		}
		
	}

	.login-img{ 
		background-image:url(/img/bg.svg);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}
	
	.margin-right-fix{
		margin-right:0px;
	}

	/* add appropriate colors to fb, twitter and google buttons */
	.fb {
		background-color: #3B5998;
		color: white;
	}
	
	.twitter {
		background-color: #55ACEE;
		color: white;
	}
	
	.google {
		background-color: #dd4b39;
		color: white;
	}
	
	.microsoft {
		background-color: #0f6cbd;
		color: white;
	}
	
	.linkedin {
		background-color: #0a66c2;
		color: white;
	}
}